import { useMemo } from "react";
import { formatRupiah } from "../../../helpers/functionFormat";

export default function Unit({
  inspection_spector,
  unit_name,
  base_price,
  multiple_bid,
  lot,
  lot_info,
}) {
  const imageUrl = useMemo(() => {
    if (!inspection_spector) return "/no-image.jpg";

    // const parsedInpectionSpector = JSON.parse(inspection_spector);

    const images = inspection_spector.MandatoryFoto;

    return images?.length ? images[0].BlobUri : "/no-image.jpg";
  }, [inspection_spector]);

  return (
    <div className="flex flex-col rounded-lg bg-white p-6 shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)] lg:w-1/2">
      <div className="h-[300px] overflow-hidden rounded-md bg-Primary-1-Main">
        <img
          src={imageUrl}
          alt={unit_name}
          className="h-full w-full object-cover"
        />
      </div>
      <div>
        <div className="mt-6 flex items-center space-x-4">
          <div className="flex w-max items-center rounded-lg bg-Primary-1-Main px-4 py-[10px]">
            <p className="text-sm font-bold text-white">Lot - {lot}</p>
          </div>
          <h6 className="text-lg font-bold text-Base-Text-Primary">
            UNIT {lot_info}
          </h6>
        </div>
        <div className="mt-3 space-y-2">
          <h6 className="text-lg font-bold text-Base-Text-Primary">
            {unit_name}
          </h6>
          <div>
            <p className="text-Base-Text-Secondary">Harga Dasar</p>
            <p className="text-xl font-bold text-Base-Text-Primary">
              {formatRupiah(base_price, ".")}
            </p>
          </div>
          <div>
            <p className="text-Base-Text-Secondary">Kelipatan Bid</p>
            <p className="text-xl font-bold text-Base-Text-Primary">
              {formatRupiah(multiple_bid, ".")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
