import Button from "../../../components/Button";
import { ReactComponent as Play } from "../../../assets/icons/play-circle-line.svg";
import { ReactComponent as Stop } from "../../../assets/icons/stop-circle-line.svg";
import { useEffect, useState } from "react";

export default function Pending({
  auction,
  stop,
  start,
  startFromPending,
  getLots,
}) {
  const [lots, setLots] = useState([]);

  const [selectedLotId, setSelectedLotId] = useState(0);

  useEffect(() => {
    if (auction.current_stock_unit_id) return;

    getLots().then((res) => setLots(res));
  }, [auction.current_stock_unit_id, getLots]);

  return (
    <div className="flex w-[350px] flex-col items-center">
      <img
        className="mb-6 h-[80px] w-[80px]"
        alt="pause"
        src={require("../../../assets/images/circle-pause.gif")}
      />
      <div className="mb-2 text-xl font-bold">Event Dijeda</div>
      {auction.status === "PENDING" && !auction.current_stock_unit_id ? (
        <>
          {lots.length ? (
            <>
              <div className=" text-sm text-[#646566]">
                Pilih Lot dan klik tombol lanjutkan event untuk melanjutkan
                event
              </div>
              <div className="relative w-[100%] flex-auto p-4 text-left">
                <div className="mb-2 text-sm font-semibold">
                  Pilih Lot Manual <span className="text-primary">*</span>
                </div>
                <select
                  onChange={(e) => setSelectedLotId(e.target.value)}
                  value={selectedLotId}
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value={0}>-Pilih Lot Manual Selanjutnya-</option>
                  {lots.map((data, idx) => (
                    <option key={idx} value={data.id}>
                      Lot - {auction.auction_lane_name}
                      {data?.lot_number}
                    </option>
                  ))}
                </select>
              </div>
              <Button
                disabled={!selectedLotId}
                className="mb-4 w-[80%] py-2"
                title={
                  <div className="flex items-center justify-center">
                    <Play />
                    <span className="ml-2">Lanjutkan Kembali</span>
                  </div>
                }
                onClick={() => start(selectedLotId)}
              />
              <div className=" text-sm text-[#646566]">atau</div>
            </>
          ) : (
            <></>
          )}

          <Button
            className="mt-4 w-[80%] py-2"
            title={
              <div className="flex items-center justify-center">
                <Stop />
                <span className="ml-2">Hentikan Event</span>
              </div>
            }
            onClick={() => stop(true)}
          />
        </>
      ) : (
        <>
          <div className=" text-sm text-[#646566]">
            Klik tombol di bawah untuk melanjutkan event
          </div>
          <Button
            className="mt-4 w-[80%] py-2"
            title={
              <div className="flex items-center justify-center">
                <Play />
                <span className="ml-2">Lanjutkan Kembali</span>
              </div>
            }
            onClick={() => startFromPending()}
          />
        </>
      )}
    </div>
  );
}
