import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { ReactComponent as EyeSVG } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeOffSVG } from '../../assets/icons/eye-off.svg';

import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { login } from '../../redux/actions';
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';

class Login extends Component {
  _mounted = true;

  state = {
    email: '',
    password: '',
    hidePassword: true,
    loading: false,
    alert: {
      show: false,
      message: '',
      isError: false,
    },
    modal: {
      show: false,
      body: '',
      onSubmit: () => {},
    },
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  onLogin = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onLogin(this.state.email, this.state.password);
      if (this._mounted) this.setState({ loading: false });
      this.props.history.replace('/beranda');
    } catch (error) {
      if (this._mounted) {
        this.setState({
          loading: false,
          modal: {
            show: true,
            body: error.message,
            onSubmit: () => {
              this.setState({
                modal: {
                  ...this.state.modal,
                  show: false,
                },
              });
            },
          },
        });
        // this.setState({loading: false});
      }
    }
  };

  

  render() {
    return (
      <div className="w-screen h-screen grid grid-cols-12 md:grid-cols-12 bg-background">
        <DotLoaderOverlay loading={this.state.loading} overlayColor="rgba(0,0,0,0.1)" color={'#85AD26'} />
        <Modal
          show={this.state.modal.show}
          body={this.state.modal.body}
          textSubmit="OK"
          onSubmit={this.state.modal.onSubmit}
        />
        <div className="col-span-6 hidden md:block">
          <img
            alt="Grosir Mobil Logo"
            className="w-full h-screen"
            src={require('../../assets/images/login-image.png')}
          />
        </div>
       
        <div className="h-screen w-full bg-white p-6 min-h-screen col-span-6 flex flex-col">
          <div className="flex justify-center">
            <div className="w-[350px]">
              <img alt="Grosirmobil Logo" className="w-[200px]" src={'grosirmobil.png'} />
            </div>
          </div>

          <div className="h-screen w-full flex">
            <div className="m-auto font-sourceSansPro">
              <h3 className="font-semibold text-xl mb-8">Masuk</h3>
              <div className="mb-6 w-80 sm:w-80 md:w-60 lg:w-80">
                <TextInput
                  containerclassname="focus-within:border-primary"
                  label={
                    <span>
                      Email <span className="text-red-500">*</span>
                    </span>
                  }
                  placeholder="Email"
                  type="email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="mb-8">
                <TextInput
                  containerclassname="focus-within:border-primary"
                  label={
                    <span>
                      Kata Sandi <span className="text-red-500">*</span>
                    </span>
                  }
                  righticon={
                    this.state.hidePassword ? (
                      <EyeSVG
                        className="hover:cursor-pointer w-6 h-6"
                        fill={'#9AA2B1'}
                        onClick={() =>
                          this.setState({
                            hidePassword: !this.state.hidePassword,
                          })
                        }
                      />
                    ) : (
                      <EyeOffSVG
                        className="hover:cursor-pointer w-6 h-6"
                        fill={'#9AA2B1'}
                        onClick={() =>
                          this.setState({
                            hidePassword: !this.state.hidePassword,
                          })
                        }
                      />
                    )
                  }
                  placeholder="Kata Sandi"
                  type={this.state.hidePassword ? 'password' : 'text'}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <Button className="w-full" title="Masuk" onClick={this.onLogin} />
            </div>
          </div>
          <div className="flex justify-center text-xs">
            <div className="w-[300px] flex ">
              <div className="mr-1 text-[#6F716A]">GROSIR MOBIL © 2020 all right reserved</div>
              <div className="h-3 w-[1px] bg-[#6F716A]"></div>
              <Link to="#" className="ml-1 text-[#85AD26] hover:cursor-pointer hover:underline">
                Bantuan
              </Link>
            </div>
            {/* <div className="text-primary hover:cursor-pointer hover:underline">Bantuan</div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(login(email, password)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
