import React, { Component } from "react";
import { connect } from "react-redux";
import { DotLoaderOverlay } from "react-spinner-overlay";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import ModalComponent from "../../components/ModalComponent";
import {
  formatDate,
  formatTime,
  formatRupiah,
} from "../../helpers/functionFormat";
import { getDetailRiwayat } from "../../redux/actions";

class DetailRiwayat extends Component {
  _mounted = true;

  state = {
    location: "",
    date: "",
    time: "",
    listData: [],
    loading: true,
    tableState: {
      auction_id: this.props.match.params.id,
      search: "",
    },
    modal: {
      title: "Daftar Penawar",
      show: false,
      body: null,
    },
  };

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.intervalTime);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateTime();
    this.loadData();
    this.intervalTime = setInterval(() => this.updateTime(), 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableState !== this.state.tableState) {
      this.loadData();
    }
  }

  updateTime() {
    let date = new Date();
    if (this._mounted) {
      this.setState({
        date: formatDate(date, 3),
        time: formatTime(date),
      });
    }
  }

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onGetDetailRiwayat(
        this.state.tableState
      );
      console.log("ini list data", response.data);

      if (this._mounted) {
        this.setState({ listData: response.data, loading: false });
      }
    } catch (error) {
      if (this._mounted) {
        this.setState({ loading: false });
      }
    }
  };

  onShowListPenawar = (data) => {
    this.setState({
      modal: {
        title: "Daftar Penawar",
        show: true,
        body: (
          <div className="max-h-96 overflow-y-scroll">
            {data.map((history, index) => (
              <div
                key={history.id}
                className={`flex justify-between items-center px-6 py-2 ${
                  index < 9 && "border-b"
                }`}
              >
                <div>
                  <div className="font-semibold">
                    {history.buyer?.full_name ?? "Offline Bidder"}
                  </div>
                  <div className="text-[#646566] text-[14px] mt-[4px]">
                    {history.buyer?.email ?? "-"}
                  </div>
                </div>
                <div className="font-semibold">
                  {history?.offer_price &&
                    formatRupiah(history.offer_price, ".")}
                </div>
              </div>
            ))}
          </div>
        ),
      },
    });
  };

  data = [
    {
      lot: "Lot-A123",
      product: "2019 DAIHATSU SIGRA 1.0M",
      price: "Rp 110.000.000",
      winner: "NPL - 4123 (Handoko Sulityo)",
      count: 10,
    },
    {
      lot: "Lot-A123",
      product: "TOYOTA KIJANG INNOVA 2.4 G",
      price: "Rp 110.000.000",
      winner: "-",
      count: 0,
    },
    {
      lot: "Lot-A123",
      product: "TOYOTA YARIS 1.5 S",
      price: "Rp 110.000.000",
      winner: "NPL - 6372 (Muhammad Naufal)",
      count: 7,
    },
    {
      lot: "Lot-A123",
      product: "TOYOTA FORTUNER 2.5 G",
      price: "Rp 110.000.000",
      winner: "NPL - 3791 (Frederick)",
      count: 2,
    },
  ];

  render() {
    return (
      <div className="pb-4">
        <DotLoaderOverlay
          loading={this.state.loading}
          overlayColor="rgba(0,0,0,0.1)"
          color={"#85AD26"}
        />
        <ModalComponent
          className="w-2/5"
          show={this.state.modal.show}
          closeModal={() =>
            this.setState((prevState) => {
              return { modal: { ...prevState.modal, show: false } };
            })
          }
          title={this.state.modal.title}
          body={this.state.modal.body}
        />
        <div className="flex px-1 md:px-24 my-8">
          <div className="md:flex md:justify-between bg-white w-2/3 md:w-4/5 p-2 rounded-lg items-center md:px-4 card">
            <span className="text-third font-semibold text-md md:text-sm">
              Jakarta | {this.state.date}
            </span>
            <div className="flex items-center mt-1 md:mt-0">
              <Clock className="mr-1 w-4 md:mr-2 md:w-6" />
              <span className="text-third text-sm">{this.state.time}</span>
            </div>
          </div>
          <Button
            bgColor="bg-third"
            className="w-1/3 md:w-1/5 ml-4  px-0 md:px-6"
            title="Kembali Ke Beranda"
            onClick={() => this.props.history.push("/beranda")}
          />
        </div>
        <div className="mx-1 md:mx-24 bg-white rounded-lg py-1 card min-h-5/6 overflow-x-scroll">
          <div className="flex items-center justify-between py-2 px-4 border-b">
            <h3 className="text-lg font-bold">Riwayat Event</h3>
            <div className="md:w-1/4 w-2/3">
              <TextInput
                className="flex flex-1 border-0 px-1 m-1 font-sourceSansPro focus:outline-none focus:border-none"
                lefticon={<Search />}
                placeholder="Cari lot atau tipe mobil"
                value={this.state.tableState.search}
                onChange={(e) =>
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      tableState: {
                        ...prevState.tableState,
                        search: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
          </div>
          {this.state.listData.map((item, index) => (
            <div
              key={index.toString()}
              className={`flex items-center px-4 py-2 justify-between ${
                index % 2 === 1 ? "bg-white" : "bg-[#F9FAFB]"
              } ${index === this.data.length - 1 ? "border-b" : ""}`}
            >
              <div className="w-1/4 md:w-3/10">
                <label className="block text-branding text-md font-semibold mb-1">
                  Lot-{item.lot_number}
                </label>
                <label className="block text-md text-third font-semibold">
                  {item.stock_unit?.unit_name}
                </label>
              </div>
              <div className="w-1/4 md:w-3/10 mx-1">
                <label className="block text-secondary text-sm mb-1">
                  Harga Penawaran
                </label>
                <label className="block text-md text-third">
                  {item?.final_price && formatRupiah(item.final_price, ".")}
                </label>
              </div>
              <div className="w-1/4 md:w-3/10 mx-1">
                <label className="block text-secondary text-sm mb-1">
                  Pemenang
                </label>
                <label className="block text-md text-third">
                  {item.buyer?.full_name ?? "-"}
                </label>
              </div>
              <div className="w-1/4 md:w-1/10">
                <label className="block text-secondary text-sm mb-1">
                  Jumlah Penawar
                </label>
                <div className="items-center">
                  <label className="mr-2 md:inline block">
                    {item.stock_unit?.auction_progresses_count}
                  </label>
                  <label
                    onClick={
                      item.stock_unit?.auction_progresses_count > 0
                        ? () =>
                            this.onShowListPenawar(
                              item.stock_unit?.auction_progresses
                            )
                        : null
                    }
                    className={`text-branding text-sm ${
                      item.stock_unit?.auction_progresses_count > 0 &&
                      "hover:cursor-pointer hover:underline"
                    }`}
                  >
                    Lihat Daftar Penawar
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDetailRiwayat: (id, params) => dispatch(getDetailRiwayat(id, params)),
  };
};

export default connect(null, mapDispatchToProps)(DetailRiwayat);
