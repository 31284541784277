import { formatDate } from "../../../helpers/functionFormat";

export default function Date({ date, time_start, time_finish }) {
  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1665 2.50005H17.4998C17.7209 2.50005 17.9328 2.58785 18.0891 2.74413C18.2454 2.90041 18.3332 3.11237 18.3332 3.33338V16.6667C18.3332 16.8877 18.2454 17.0997 18.0891 17.256C17.9328 17.4123 17.7209 17.5 17.4998 17.5H2.49984C2.27882 17.5 2.06686 17.4123 1.91058 17.256C1.7543 17.0997 1.6665 16.8877 1.6665 16.6667V3.33338C1.6665 3.11237 1.7543 2.90041 1.91058 2.74413C2.06686 2.58785 2.27882 2.50005 2.49984 2.50005H5.83317V0.833382H7.49984V2.50005H12.4998V0.833382H14.1665V2.50005ZM12.4998 4.16671H7.49984V5.83338H5.83317V4.16671H3.33317V7.50005H16.6665V4.16671H14.1665V5.83338H12.4998V4.16671ZM16.6665 9.16671H3.33317V15.8334H16.6665V9.16671Z"
            fill="#8F918A"
          />
        </svg>
        <p className="text-sm text-Base-Text-Primary">
          {formatDate(date ?? new Date(), 3)}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99984 18.3334C5.39734 18.3334 1.6665 14.6025 1.6665 10C1.6665 5.39754 5.39734 1.66671 9.99984 1.66671C14.6023 1.66671 18.3332 5.39754 18.3332 10C18.3332 14.6025 14.6023 18.3334 9.99984 18.3334ZM9.99984 16.6667C11.7679 16.6667 13.4636 15.9643 14.7139 14.7141C15.9641 13.4638 16.6665 11.7682 16.6665 10C16.6665 8.23193 15.9641 6.53624 14.7139 5.286C13.4636 4.03576 11.7679 3.33338 9.99984 3.33338C8.23173 3.33338 6.53604 4.03576 5.28579 5.286C4.03555 6.53624 3.33317 8.23193 3.33317 10C3.33317 11.7682 4.03555 13.4638 5.28579 14.7141C6.53604 15.9643 8.23173 16.6667 9.99984 16.6667ZM10.8332 10H14.1665V11.6667H9.1665V5.83338H10.8332V10Z"
            fill="#8F918A"
          />
        </svg>
        <p className="text-sm text-Base-Text-Primary">
          {time_start.substr(0, 5)} - {time_finish.substr(0, 5)} WIB
        </p>
      </div>
    </div>
  );
}
