import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import TopNav from '../components/TopNav';

const Layout = props => {

    const { children, profile } = props;

    return (
        <BrowserRouter>
            
                <Route render={(props) => (
                    <div className="flex min-h-screen h-full w-full bg-background font-sourceSansPro">
                        <div className="flex-1">
                            <div className="mb-20">
                                <TopNav profile={profile} {...props} />
                            </div>
                            {children}
                        </div>
                    </div>
                )} />
            
        </BrowserRouter>
    )
}

export default Layout;