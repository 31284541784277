import React from "react";
import { useState } from "react";
import Button from "./Button";
import { ReactComponent as StopFill } from "../assets/icons/stop-circle-fill.svg";
import { useHistory } from "react-router-dom";

const ModalHentikanLelang = (props) => {
  let history = useHistory();

  const { show, body, type, onSubmit } = props;

  const [id, setId] = useState("");

  const onChange = (val) => {
    setId(val.target.value);
  };

  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-sourceSansPro">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[400px] bg-white outline-none focus:outline-none">
            <div className="p-6 border-solid border-slate-200 rounded-t">
              <h3 className="text-lg font-sourceSansPro font-semibold text-center">
                <div className="flex flex-col items-center justify-center">
                  <StopFill />
                  <span className="mt-5 mb-3 text-black text-lg">
                    {type === "NOT_SOLD"
                      ? "Event Dihentikan & Unit Belum Terjual"
                      : "Event Dihentikan & Unit Telah Terjual"}
                  </span>
                </div>
              </h3>
              <div className="text-center text-[#646566] text-sm">
                {type === "NOT_SOLD"
                  ? "Unit dianggap belum terjual karena tidak ada bidder yang melakukan bid."
                  : "Unit dianggap terjual karena telah ada bidder yang melakukan bid. " +
                    (type === "OFFLINE" && "Silahkan pilih pemenang.")}
              </div>
            </div>
            {type === "OFFLINE" && (
              <div className="relative pt-0 p-6 flex-auto max-w-80">
                <div className="mb-2 font-semibold">
                  NIPL Pemenang <span className="text-primary">*</span>
                </div>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(data) => onChange(data)}
                >
                  <option value="">-Pilih Pemenang-</option>
                  {body.map((data, idx) => (
                    <option key={idx} value={data.id}>
                      {data.nipl +
                        " - " +
                        data.buyer?.user?.name +
                        " (" +
                        data.buyer?.user?.email +
                        ")"}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(type === "NOT_SOLD" || type === "SOLD") && (
              <>
                <div className="flex flex-col pt-0 p-6 rounded-b w-[100%] self-center">
                  <Button
                    className="mb-4 py-2 font-semibold"
                    title={"Lihat Riwayat Event"}
                    onClick={() => history.push("/riwayat")}
                  />
                  <Button
                    bgColor="bg-[#FFEBEC]"
                    color="text-primary"
                    className="py-2 font-semibold"
                    title={"Kembali ke Beranda"}
                    onClick={() => history.push("/")}
                  />
                </div>
              </>
            )}
            {type === "OFFLINE" && (
              <div className="flex flex-col pt-0 p-6 rounded-b w-[100%] self-center">
                <Button
                  className="py-2 mb-2"
                  title={"Submit Pemenang"}
                  onClick={() => {
                    id && onSubmit(id);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default ModalHentikanLelang;
