export default function ActionButtons({ pause, stop }) {
  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={stop}
        type="button"
        className="flex gap-2 rounded-lg border border-Base-Border-Interactive px-6 py-3 font-semibold text-Base-Text-Primary"
      >
        <svg
          className="relative top-[2.5px]"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99984 18.3333C5.39734 18.3333 1.6665 14.6025 1.6665 10C1.6665 5.3975 5.39734 1.66666 9.99984 1.66666C14.6023 1.66666 18.3332 5.3975 18.3332 10C18.3332 14.6025 14.6023 18.3333 9.99984 18.3333ZM9.99984 16.6667C11.7679 16.6667 13.4636 15.9643 14.7139 14.714C15.9641 13.4638 16.6665 11.7681 16.6665 10C16.6665 8.23189 15.9641 6.5362 14.7139 5.28595C13.4636 4.03571 11.7679 3.33333 9.99984 3.33333C8.23173 3.33333 6.53604 4.03571 5.28579 5.28595C4.03555 6.5362 3.33317 8.23189 3.33317 10C3.33317 11.7681 4.03555 13.4638 5.28579 14.714C6.53604 15.9643 8.23173 16.6667 9.99984 16.6667ZM7.49984 7.5H12.4998V12.5H7.49984V7.5Z"
            fill="#1A1E12"
          />
        </svg>
        Hentikan
      </button>
      <button
        onClick={pause}
        type="button"
        className="flex gap-2 rounded-lg border border-Primary-1-Background bg-Primary-1-Background px-6 py-3 font-semibold text-Primary-1-Main"
      >
        <svg
          className="relative top-[2.5px]"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99984 18.3333C5.39734 18.3333 1.6665 14.6025 1.6665 10C1.6665 5.3975 5.39734 1.66666 9.99984 1.66666C14.6023 1.66666 18.3332 5.3975 18.3332 10C18.3332 14.6025 14.6023 18.3333 9.99984 18.3333ZM9.99984 16.6667C11.7679 16.6667 13.4636 15.9643 14.7139 14.714C15.9641 13.4638 16.6665 11.7681 16.6665 10C16.6665 8.23189 15.9641 6.5362 14.7139 5.28595C13.4636 4.03571 11.7679 3.33333 9.99984 3.33333C8.23173 3.33333 6.53604 4.03571 5.28579 5.28595C4.03555 6.5362 3.33317 8.23189 3.33317 10C3.33317 11.7681 4.03555 13.4638 5.28579 14.714C6.53604 15.9643 8.23173 16.6667 9.99984 16.6667ZM7.49984 7.5H9.1665V12.5H7.49984V7.5ZM10.8332 7.5H12.4998V12.5H10.8332V7.5Z"
            fill="#85AD26"
          />
        </svg>
        Jeda
      </button>
    </div>
  );
}
