import axios from 'axios';

import * as actionTypes from "../actionTypes";

export const getListBeranda = (params) => {
    return async (dispatch, getState) => {
        const url = getState().config.url;
        const tokenType = getState().auth.tokenType;
        const token = getState().auth.token;

        try{
            const response = await axios.get(
                `${url}/conductor/auction`,
                { 
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': `${tokenType} ${token}`
                    },
                    params: params
                }
            );
            return response.data
        }catch(error){
            let message = error?.response?.data?.message ?? 'Internal Server Error';
            if(error?.response?.status === 401){
                dispatch({ type: actionTypes.LOGOUT })
            }else {
                if(Object.isExtensible(error?.response?.data?.message ?? message)){
                    let key = Object.keys(error?.response?.data?.message)[0];
                    message = error?.response?.data?.message[key];
                }
            }
            throw new Error(message);
        }
    }
}

export const getDetailBeranda = (id) => {
    return async (dispatch, getState) => {
        const url = getState().config.url;
        const tokenType = getState().auth.tokenType;
        const token = getState().auth.token;

        try{
            const response = await axios.get(
                `${url}/conductor/auction/${id}`,
                { 
                    headers: { 
                        'Accept': 'application/json',
                        'Authorization': `${tokenType} ${token}`
                    }
                }
            );
            return response.data
        }catch(error){
            let message = error?.response?.data?.message ?? 'Internal Server Error';
            if(error?.response?.status === 401){
                dispatch({ type: actionTypes.LOGOUT })
            }else {
                if(Object.isExtensible(error?.response?.data?.message ?? message)){
                    let key = Object.keys(error?.response?.data?.message)[0];
                    message = error?.response?.data?.message[key];
                }
            }
            throw new Error(message);
        }
    }
}