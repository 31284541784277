import "pusher-js";
import axios from "axios";
import Echo from "laravel-echo";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useLiveBidding() {
  const { token, tokenType } = useSelector((state) => state.auth);

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [auction, setAuction] = useState(auctionInitial);
  const [lastBid, setLastBid] = useState(lastBidInitial);
  const [latency, setLatency] = useState(0);

  const [modal, setModal] = useState({
    show: false,
    title: "",
    body: "",
    textSubmit: "",
    textCancel: null,
    onSubmit: () => alert("unhandled func"),
    closeModal: () => alert("unhandled func"),
  });

  const [modalHentikan, setModalHentikan] = useState({
    show: false,
    body: "",
    type: "NOT_SOLD",
  });

  function closeModal() {
    setModal((modal) => ({ ...modal, show: false }));
  }

  function alert(body, errorResponse) {
    if (errorResponse) {
      if (errorResponse.response) {
        if (errorResponse.response.data.message)
          body = errorResponse.response.data.message;
        else if (errorResponse.response.data.errors)
          body = errorResponse.response.data.errors.details;
      } else {
        body = errorResponse.message;
      }
    }

    setModal({
      show: true,
      title: "Peringatan",
      body,
      textSubmit: "Ok",
      textCancel: false,
      closeModal,
      onSubmit: closeModal,
    });
  }

  const getAuction = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/conductor/v3/detail-auction/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `${tokenType} ${token}`,
          },
        },
      );

      setAuction(
        res.data?.data ?? {
          id: 0,
          name: "",
          date_start: "2024-01-31",
          time_start: "02:00:00",
          date_finish: "2024-01-31",
          time_finish: "23:00:00",
          location_name: null,
          auction_lane_name: "A",
          status: "COMING",
          current_stock_unit_id: 0,
          unit_type_id: 1,
          unit_name: "-",
          base_price: 0,
          direct_sales_price: 0,
          lot_number: 1,
          inspection_spector: null,
          started_at: "2024-02-07 11:09:21",
          blocked_at: "2999-02-07 11:10:25",
          unit_type_name: "Mobil",
          multiple_bid: 0,
          proxy_bidders: [],
        },
      );
    } catch (error) {
      alert("Internal Server Error", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token, tokenType]);

  const getLastBid = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/conductor/v3/detail-auction/${id}/last_bid`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `${tokenType} ${token}`,
          },
        },
      );

      setLastBid(
        res.data?.data
          ? res.data.data
          : {
              id: 0,
              stock_unit_id: 0,
              auction_id: 0,
              offer_price: "0",
              status: null,
              type: "Online Bidder",
              buyer_email: "",
              buyer_name: "",
              source_bid: "OFFLINE",
            },
      );
    } catch (error) {
      alert("Internal Server Error", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token, tokenType]);

  function start(unit_id) {
    setModal({
      show: true,
      title: "Apa Anda yakin mulai event sekarang?",
      body: "",
      closeModal: () => {},
      onSubmit: async () => {
        try {
          setLoading(true);

          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/start`,
            unit_id ? { unit_id } : null,
            {
              headers: {
                Accept: "application/json",
                Authorization: `${tokenType} ${token}`,
              },
            },
          );

          closeModal();
        } catch (error) {
          alert("Internal Server Error", error);
        } finally {
          setLoading(false);
        }
      },
      textCancel: true,
      textSubmit: "Mulai",
    });
  }

  function pause() {
    setModal({
      show: true,
      title: "Apa Anda yakin menjeda event sekarang?",
      textSubmit: "Jeda",
      textCancel: true,
      onSubmit: async () => {
        try {
          setLoading(true);

          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/pending`,
            null,
            {
              headers: {
                Accept: "application/json",
                Authorization: `${tokenType} ${token}`,
              },
            },
          );

          closeModal();
        } catch (error) {
          alert("Internal Server Error", error);
        } finally {
          setLoading(false);
        }
      },
    });
  }

  async function startFromPending(unit_id) {
    try {
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/start-pending`,
        { unit_id: unit_id ?? auction.current_stock_unit_id },
        {
          headers: {
            Accept: "application/json",
            Authorization: `${tokenType} ${token}`,
          },
        },
      );
    } catch (error) {
      alert("Internal Server Error", error);
    } finally {
      setLoading(false);
    }
  }

  function stop(withConfirmation = false) {
    async function stopAuction() {
      try {
        setLoading(true);

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/stop`,
          null,
          {
            headers: {
              Accept: "application/json",
              Authorization: `${tokenType} ${token}`,
            },
          },
        );

        setModalHentikan({
          body: "",
          show: true,
          type: lastBid.id === 0 ? "NOT_SOLD" : "SOLD",
        });
      } catch (error) {
        alert("Internal Server Error", error);
      } finally {
        setLoading(false);
      }
    }

    if (withConfirmation)
      setModal({
        show: true,
        title: "Apa Anda yakin menghentikan event sekarang?",
        textSubmit: "Hentikan",
        textCancel: true,
        onSubmit: () => {
          closeModal();
          stopAuction();
        },
      });
    else stopAuction();
  }

  function notSold(withConfirmation) {
    async function _notSold() {
      try {
        setLoading(true);

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/not-sold`,
          null,
          {
            headers: {
              Accept: "application/json",
              Authorization: `${tokenType} ${token}`,
            },
          },
        );

        alert("Unit tidak terjual.");

        setTimeout(() => closeModal(), 3000);
      } catch (error) {
        alert("Internal Server Error", error);
      } finally {
        setLoading(false);
      }
    }

    if (withConfirmation)
      setModal({
        show: true,
        title: "Apa anda yakin tidak ingin menjual unit ini?",
        textSubmit: "Ya",
        textCancel: true,
        onSubmit: () => {
          closeModal();
          _notSold();
        },
      });
    else _notSold();
  }

  function sold(withConfirmation) {
    async function _sold() {
      try {
        setLoading(true);

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/conductor/v3/auction/${id}/sold`,
          { buyer_nipl_id: auction.current_stock_unit_id },
          {
            headers: {
              Accept: "application/json",
              Authorization: `${tokenType} ${token}`,
            },
          },
        );

        alert("Unit berhasil terjual.");

        setTimeout(() => closeModal(), 3000);
      } catch (error) {
        alert("Internal Server Error", error);
      } finally {
        setLoading(false);
      }
    }

    if (withConfirmation)
      setModal({
        show: true,
        title: "Apa anda yakin ingin menjual unit ini?",
        textSubmit: "Ya",
        textCancel: true,
        onSubmit: () => {
          closeModal();
          _sold();
        },
      });
    else _sold();
  }

  const getLots = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/conductor/v2/detail-auction/${id}/next-lot/null`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `${tokenType} ${token}`,
          },
        },
      );
      return res.data.data;
    } catch (error) {
      alert("Internal Server Error", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tokenType, token]);

  useEffect(() => {
    getAuction();
    getLastBid();
  }, [getAuction, getLastBid]);

  // WS
  function onBuyItNow() {
    alert("Unit berhasil dibeli");

    setTimeout(() => {
      closeModal();
    }, 3000);
  }

  async function onAuctionAll(data) {
    const serverTimestamp = new Date(data.timestamps).getTime();

    const clientTimestamp = Date.now();

    const newLatency = clientTimestamp - serverTimestamp;

    setLatency(newLatency >= 0 ? newLatency : 0);

    await Promise.all([getAuction(), getLastBid()]);
  }

  function onAuctionBidder(data) {
    setLastBid(data.auction_progress);

    setAuction((auction) => ({
      ...auction,
      blocked_at: data.blocked_at,
    }));

    const serverTimestamp = new Date(data.timestamps).getTime();

    const clientTimestamp = Date.now();

    const newLatency = clientTimestamp - serverTimestamp;

    setLatency(newLatency >= 0 ? newLatency : 0);
  }

  useEffect(() => {
    let echoInstance;

    try {
      echoInstance = new Echo({
        broadcaster: "pusher",
        key: process.env.REACT_APP_WS_KEY,
        cluster: process.env.REACT_APP_WS_CLUSTER,
        forceTLS: false,
        authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `${tokenType} ${token}`,
            Accept: "application/json",
          },
        },
      });

      const startTime = Date.now();

      echoInstance.connector.pusher.connection.bind("connected", () => {
        const endTime = Date.now();
        const initialLatency = endTime - startTime;
        setLatency(initialLatency);
      });

      echoInstance
        .private(`auction.${id}`)
        .listen(".auction.all", onAuctionAll)
        .listen(".auction.bidder", onAuctionBidder)
        .listen(".auction.buy_it_now", onBuyItNow);
    } catch (error) {
      console.log("websocket error", error);
    }

    return () => {
      if (echoInstance) {
        echoInstance.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id, tokenType]);

  return {
    auction,
    lastBid,
    loading,
    pause,
    start,
    startFromPending,
    stop,
    notSold,
    sold,
    modal,
    closeModal,
    modalHentikan,
    getLots,
    latency,
  };
}

const auctionInitial = {
  id: 0,
  name: "",
  date_start: "2024-01-31",
  time_start: "02:00:00",
  date_finish: "2024-01-31",
  time_finish: "23:00:00",
  location_name: null,
  auction_lane_name: "A",
  status: "COMING",
  current_stock_unit_id: 0,
  unit_type_id: 1,
  unit_name: "-",
  base_price: 0,
  direct_sales_price: 0,
  lot_number: 1,
  inspection_spector: null,
  started_at: "2024-02-07 11:09:21",
  blocked_at: "2999-02-07 11:10:25",
  unit_type_name: "Mobil",
  multiple_bid: 0,
  proxy_bidders: [],
};

const lastBidInitial = {
  id: 0,
  stock_unit_id: 0,
  auction_id: 0,
  offer_price: "0",
  status: null,
  type: "Online Bidder",
  buyer_email: "",
  buyer_name: "",
  source_bid: "OFFLINE",
};
