import Button from "../../../components/Button";
import { ReactComponent as Play } from "../../../assets/icons/play-circle-line.svg";

export default function StartAuction({ start }) {
  return (
    <div className="flex h-auto flex-col items-center justify-center rounded-lg bg-Base-Background p-6 shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)] lg:w-1/2">
      <img
        alt="waiting"
        className="mt-2 h-[80px] w-[40px]"
        src={require("../../../assets/images/sand-timer.png")}
      />
      <h3 className="mt-6 text-xl font-bold text-Base-Text-Primary">
        Event Belum Dimulai
      </h3>
      <p className="text-sm text-secondary">
        Silahkan klik pada tombol di bawah untuk memulai event{" "}
      </p>
      <Button
        onClick={start}
        className="mt-4 w-[45%] py-2"
        title={
          <div className="flex items-center justify-center">
            <Play />
            <span className="ml-2">Mulai Event</span>
          </div>
        }
      />
    </div>
  );
}
