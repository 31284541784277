export default function Ping({ latency }) {
  return (
    <div className="space-y-1">
      <div className="flex items-center space-x-1">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 8.77629L3 10.7763C7.97 5.80629 16.03 5.80629 21 10.7763L23 8.77629C16.93 2.70629 7.08 2.70629 1 8.77629ZM9 16.7763L12 19.7763L15 16.7763C13.35 15.1163 10.66 15.1163 9 16.7763ZM5 12.7763L7 14.7763C9.76 12.0163 14.24 12.0163 17 14.7763L19 12.7763C15.14 8.91629 8.87 8.91629 5 12.7763Z"
            fill={
              latency < 100 ? "#1D8242" : latency > 300 ? "#B33636" : "#EA9437"
            }
          />
        </svg>
        <p
          className={`font-semibold ${
            latency < 100
              ? "text-[#1D8242]"
              : latency > 300
                ? "text-[#B33636]"
                : "text-[#EA9437]"
          }`}
        >
          {latency}ms
        </p>
      </div>
      <p
        className={`text-sm ${
          latency < 100
            ? "text-[#1D8242]"
            : latency > 300
              ? "text-[#B33636]"
              : "text-[#EA9437]"
        }`}
      >
        {latency < 100
          ? "(Internet Stabil)"
          : latency > 300
            ? "(Internet Tidak Stabil)"
            : "(Internet Kurang Stabil)"}
      </p>
    </div>
  );
}
