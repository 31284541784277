import { useEffect, useState } from "react";

export default function Timer({ blocked_at, lastBid, notSold, sold, status }) {
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    // updates time diff by looking at the comparison between blocked_at timestamp and timestamp every 500ms
    const interval = setInterval(() => {
      const blocked_at_timestamp = new Date(blocked_at).getTime();

      const timestamp = Date.now();

      const timeDiff = blocked_at_timestamp - timestamp;

      const result = parseInt(timeDiff / 1000); // ms to sec

      if (result <= 0) clearInterval(interval);

      setCountdown(result);
    }, 500);

    return () => clearInterval(interval);
  }, [blocked_at]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (status !== "STARTED" || countdown > 0) return clearTimeout(timeout);

      lastBid.id ? sold() : notSold();
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, countdown, lastBid.id]);

  return (
    <div className="flex items-center justify-between rounded-lg border border-Base-Text-Primary px-6 py-[10px]">
      <h5 className="text-lg font-semibold text-Base-Text-Primary">
        Countdown
      </h5>
      <h4 className="text-2xl font-bold text-Base-Text-Primary">
        {numberToTime(countdown >= 0 ? countdown : 0)}
      </h4>
    </div>
  );
}

function numberToTime(num) {
  let minutes = Math.floor(num / 60);
  let seconds = num % 60;
  if (seconds.toString().length < 2) seconds = "0" + seconds;
  if (minutes.toString().length < 2) minutes = "0" + minutes;

  return `${minutes} : ${seconds}`;
}
