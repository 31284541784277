export default function BidAction({
  offer_price,
  sold,
  notSold,
  multiple_bid,
}) {
  return (
    <div className="space-y-4 rounded-xl bg-white p-6 shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)]">
      <div className="flex items-center overflow-hidden rounded-lg border border-Base-Border-Interactive">
        <div className="border-r border-Base-Border-Interactive bg-Base-Background p-3">
          <p className="text-Base-Text-Secondary">Rp</p>
        </div>
        <p className="px-3 text-Base-Text-Primary">
          {(parseInt(offer_price) + parseInt(multiple_bid)).toLocaleString(
            "id-ID",
          )}
        </p>
      </div>
      <div className="flex items-center space-x-4">
        <button
          onClick={notSold}
          type="button"
          className="w-full rounded-lg bg-Error-Main px-6 py-3 text-white shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_0.5px_2px_0px_rgba(61,_75,_92,_0.12)]"
        >
          Belum Terjual
        </button>
        <button
          onClick={sold}
          type="button"
          className="w-full rounded-lg bg-Success-Main px-6 py-3 text-white shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_0.5px_2px_0px_rgba(61,_75,_92,_0.12)]"
        >
          Terjual
        </button>
      </div>
    </div>
  );
}
