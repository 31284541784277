import React from "react";
import Button from "./Button";

const Modal = (props) => {
  const {
    show,
    closeModal,
    title,
    body,
    onSubmit,
    onCancel,
    textSubmit,
    textCancel,
    noFooter,
  } = props;

  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-sourceSansPro">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {title && (
              <div className="flex items-start justify-center p-6 border-solid border-slate-200 rounded-t">
                <h3 className="text-lg font-sourceSansPro font-semibold text-center">
                  {title}
                </h3>
              </div>
            )}
            {body && (
              <div className="relative p-4 flex-auto max-w-80">
                <div className="my-2 text-third text-lg leading-relaxed text-center">
                  {body}
                </div>
              </div>
            )}
            {!noFooter && (
              <div className="flex flex-col p-4 rounded-b w-80 self-center">
                {textSubmit && (
                  <Button
                    className="mb-2 py-2"
                    title={textSubmit}
                    onClick={onSubmit}
                  />
                )}
                {textCancel && (
                  <Button
                    bgColor="bg-[#F5FBE7]"
                    color="text-third"
                    className="mb-2 border py-2"
                    title={<span className="text-branding">{textCancel}</span>}
                    onClick={onCancel ? onCancel : closeModal}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default Modal;
