import ActionButtons from "./ActionButtons";
import BidAction from "./BidAction";
import Timer from "./Timer";
import Date from "./Date";
import LatestBidder from "./LatestBidder";
import Line from "./Line";
import Location from "./Location";
import Ping from "./Ping";
import ProxyBidder from "./ProxyBidder";
import Unit from "./Unit";
import useLiveBidding from "./useLiveBidding";
import StartAuction from "./StartAuction";
import { DotLoaderOverlay } from "react-spinner-overlay";
import Modal from "../../../components/Modal";
import ModalHentikanLelang from "../../../components/ModalHentikanLelang";
import Pending from "./Pending";

export default function LiveBiddingPage() {
  const {
    auction,
    lastBid,
    loading,
    start,
    pause,
    startFromPending,
    stop,
    notSold,
    sold,
    modal,
    modalHentikan,
    closeModal,
    getLots,
    latency,
  } = useLiveBidding();

  return (
    <>
      {auction.status === "CLOSED" ? (
        <div className="mx-2 grid grid-cols-12 gap-6 md:mx-24">
          <div className="card col-span-12 grid h-[36rem] items-center justify-center rounded-lg bg-primary">
            <label className="text-3xl text-white">Event Telah Selesai</label>
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-[1328px] space-y-6 px-8 pb-8">
          <div className="flex items-center justify-between rounded-xl bg-white py-3 pl-6 pr-3 shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)]">
            <div className="flex items-start space-x-6">
              <Location
                name={auction.name}
                location_name={auction.location_name}
              />
              <Line />
              <Date
                date={auction.date_start}
                time_start={auction.time_start}
                time_finish={auction.time_finish}
              />
              <Line />
              <Ping latency={latency} />
            </div>
            {auction.status === "STARTED" ? (
              <ActionButtons pause={pause} stop={stop} />
            ) : (
              <></>
            )}
          </div>
          <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
            <Unit
              lot_info={`${auction.first_lot?.lot_number ?? auction.lot_number}/${auction.lot_count}`}
              inspection_spector={
                auction.first_lot?.inspection_spector ??
                auction.inspection_spector
              }
              lot={`${auction.auction_lane_name}${auction.first_lot?.lot_number ?? auction.lot_number}`}
              unit_name={auction.first_lot?.unit_name ?? auction.unit_name}
              base_price={auction.first_lot?.base_price ?? auction.base_price}
              multiple_bid={auction.multiple_bid}
            />
            {auction.status !== "COMING" ? (
              <div className="space-y-6 lg:w-1/2">
                <div className="flex h-[308px] space-x-6">
                  <ProxyBidder proxy_bid={auction.proxy_bidders} />
                  <LatestBidder
                    type={lastBid.type}
                    lot={`${auction.auction_lane_name}${auction.first_lot?.lot_number ?? auction.lot_number}`}
                    offer_price={lastBid.offer_price}
                    buyer_email={lastBid.buyer_email}
                    buyer_name={lastBid.buyer_name}
                  />
                </div>
                <Timer
                  status={auction.status}
                  blocked_at={auction.blocked_at}
                  lastBid={lastBid}
                  sold={sold}
                  notSold={notSold}
                />
                <BidAction
                  multiple_bid={auction.multiple_bid}
                  offer_price={
                    lastBid.offer_price === "0"
                      ? auction.base_price
                      : lastBid.offer_price
                  }
                  notSold={() => notSold(true)}
                  sold={() => sold(true)}
                />
              </div>
            ) : (
              <StartAuction start={() => start()} />
            )}
          </div>
        </div>
      )}

      <DotLoaderOverlay
        loading={loading}
        overlayColor="rgba(0,0,0,0.1)"
        color="#85AD26"
        size={30}
      />
      <Modal
        show={auction.status === "PENDING"}
        noFooter={true}
        closeModal={() => {}}
        body={
          <Pending
            start={start}
            auction={auction}
            getLots={getLots}
            startFromPending={startFromPending}
            stop={stop}
          />
        }
      />
      <ModalHentikanLelang
        show={modalHentikan.show}
        onSubmit={modalHentikan.onSubmit}
        type={modalHentikan.type}
        body={modalHentikan.body}
      />
      <Modal
        show={modal.show}
        closeModal={closeModal}
        title={modal.title}
        body={modal.body}
        onSubmit={modal.onSubmit}
        textSubmit={modal.textSubmit}
        textCancel={modal.textCancel ? "Batal" : null}
      />
    </>
  );
}
