import { formatRupiah } from "../../../helpers/functionFormat";

export default function ProxyBidder({ proxy_bid }) {
  return (
    <div className="flex">
      <div className="flex w-[240px] flex-col rounded-lg bg-white shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)]">
        <div className="p-5">
          <p className="text-lg font-bold text-Base-Text-Primary">Proxy Bid</p>
        </div>
        <div className="h-px w-full bg-Base-Border-Subtle"></div>
        <div className="flex h-full w-full flex-col overflow-hidden">
          <div className="h-full w-full divide-y divide-Base-Border-Subtle overflow-y-auto overflow-x-hidden">
            {proxy_bid.map((el) => (
              <div key={el} className="space-y-1 px-5 py-3">
                <p className="font-bold text-Base-Text-Primary">
                  {formatRupiah(el.offer_price, ".")}
                </p>
                <p className="text-sm text-Base-Text-Secondary">
                  {el.full_name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
