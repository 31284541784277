import { formatRupiah } from "../../../helpers/functionFormat";

export default function LatestBidder({
  lot,
  offer_price,
  buyer_email,
  buyer_name,
  type,
}) {
  return (
    <div className="flex w-full flex-col rounded-xl bg-white shadow-[0px_0px_1px_0px_rgba(61,_75,_92,_0.05),_0px_2px_4px_0px_rgba(61,_75,_92,_0.12)]">
      <div className="flex items-center justify-between p-5">
        <h5 className="text-lg font-bold text-Base-Text-Primary">Bidding</h5>
        <div className="rounded-xl bg-Primary-1-Main px-4 py-[10px]">
          <p className="text-sm font-bold text-white">Lot-{lot}</p>
        </div>
      </div>
      <div className="flex h-full w-full flex-col justify-center space-y-4 bg-Primary-1-Background p-4">
        <h3 className="text-[32px] font-semibold text-Primary-1-Main">
          {offer_price !== "0" ? formatRupiah(offer_price, ".") : ""}
        </h3>
        <div className="space-y-1">
          <h5 className="text-lg font-semibold text-Base-Label">
            {type === "Proxy Bidder" ? "Proxy Bid" : buyer_name}
          </h5>
          <p className="text-sm text-Base-Text-Secondary">{buyer_email}</p>
        </div>
      </div>
    </div>
  );
}
